import React, {useState, useRef} from 'react';
import { useNavigate} from 'react-router-dom';
import { 
    Container,
    Grid,
    Button,
    Typography,
    Box,
    Stack,
    Fade,


} from '@mui/material';
import Virgin from '../assets/img/VL4.svg';
import bgImg from '../assets/img/weed3.png';
import '../assets/sass/age.scss';
import { useEffect } from 'react';





export default function AgeScreen() {
    
   const [doIt, setDoIt] = useState(true)
    const navigate = useNavigate()
    const domRef = useRef()
    

    const navigateToMenu =() => {
        navigate("/menu")
      
    }
    

    useEffect(()=> {
        const observer = new  IntersectionObserver (enteries => {
            if(enteries[0].isIntersecting) {
                setDoIt(true);
                observer.unobserve(domRef.current);
            }
        });

        observer.observe(domRef.current);
    },[]);
    

    return(
        <React.Fragment>
        <div className='menu-div'>
            
            <Container 
            maxWidth="xl"
             sx={{
                backgroundImage:`url(${bgImg})`,
                backgroundPosition: 'center',
                backgrounImage:'cover',
                backgroundRepeat: 'no-repeat',
                height:'100vh'
            }}>
                
                <Grid container justifyContent="center" alignItems="center" direction="column">
                    <Grid item xs={12} md={6}>
                    <Fade ref={domRef} in={doIt} timeout={{appear: 3000, enter: 6000, exit: 10000,}}
                    
                    >
                        <Box 
                        component="img"
                        alt="virgin cannabis"
                        src={Virgin}
                        sx={{width: '100%'}}/>
                    </Fade>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <Typography sx={{color:'white', textAlign:'center',}} variant="h3">

                        Are you at least 21?
                        </Typography>
                        <Grid item md={12}>
                            <Stack direction="row">
                            <Button sx={{ ml: 5, bgcolor:'primary.main', color:'white',width:'150px', fontSize:'1.5em', mt: 5,}} onClick={navigateToMenu}>Yes</Button>
                            <Button sx={{ ml: 2, bgcolor:'secondary.main', color:'white',width:'150px', fontSize:'1.5em', mt: 5,}}><a className="a-age" href="https://www.oregon.gov/oha/ph/PREVENTIONWELLNESS/MARIJUANA/Pages/laws.aspx" rel="noreferrer" target="_blank">NO</a></Button>
                            </Stack>

                        
                        </Grid>
                        
                    </Grid>
                </Grid>
            
            </Container>
           
            </div>
        </React.Fragment>
    )
}