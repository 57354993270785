import React from 'react';
import {Container} from '@mui/material';
import MenuItems from '../components/menuItems';
import Footer from '../components/footer';
import Navbar from '../components/navbar';
import '../assets/sass/main.scss';



export default function Menu() {
  
   
    

    return(
        <React.Fragment>
        <Navbar/>
        <div className="menu-div">
            <Container size="xl">
                    <MenuItems />
            </Container>
         </div>
         <div>
            <Footer/>
         </div>
        </React.Fragment>
        
    )
   
}