import React from 'react';
import {
    Card,
    CardHeader,
    CardMedia,
    CardContent,
    CardActions,
    Avatar,
    Typography,
    Stack,
    Grid,
    Divider,
    Box,
   
} from '@mui/material'

import V from '../assets/img/virginV.svg';

import '../assets/sass/productCard.scss';
import { QRCodeSVG } from 'qrcode.react';
import '../assets/sass/main.scss';







export default function Product(props) {

    return(
        <React.Fragment>
       
            <Card
            id={props.id}
            sx={{
                maxWidth:'22rem', 
                mt: 5,
                mb: 5,
                backgroundColor:'white', 
                color:'black', 
                border:'1px gold solid',
                transition: 'all .5s ease-in-out',
                '&:hover': {
                   
                    boxShadow: '1px 2px 20px gray'
                }
            }}>
                <CardHeader
                avatar={
                    <Avatar sx={{backgroundColor:'black',}} aria-label="Cannabis-Product">
                        <Box 
                        component="img"
                        sx={{width:'60px'}}
                        src={V}/>
                    </Avatar>
                }
               
                title={
                    <Typography variant="h6" sx={{textAlign:"start"}}>
                        {props.product}
                    </Typography>
                }
                subheader={
                    <Typography variant="body1"  sx={{textAlign:"start"}}>
                        {props.type}
                    </Typography>
                }
                />
                <CardMedia
                component="img"
                height="200"
                image={props.img}
                alt='Virgin Cannabis'
                sx={{backgroundColor:'black',height:'200px'}}
                />
                <CardContent sx={{mt: 0, minHeight:'350px',maxHeight:'350px',}}>
                    <Typography variant="body1" sx={{textAlign:'center', mb: 1,}}>
                        {props.des}
                    </Typography>
                    <Divider/>
                    <CardActions>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Stack alignItems="center" spacing={1}>
                               
                                <Typography
                                textAlign="center">
                                THC: {props.thc} %
                                </Typography>
                                <Typography
                                textAlign="center">
                                Terpenes: {props.terps} %
                                </Typography>
                                <Box>
                                <a href={props.value} style={{color:'black'}} rel="noreferrer" target="_blank">
                                <Typography sx={{textAlign:'center',mb:1, color:'black'}}>
                                See our test Results
                                </Typography>
                                <QRCodeSVG size="150" value={props.value} />
                                </a>
                                </Box>
                                
                            </Stack>
                           
                        </Grid>
                        
                    </Grid>
                   
                    </CardActions>
                </CardContent>
            </Card>
        </React.Fragment>
    )
}
