import React, {useState, useRef} from 'react';
import {
    Button,
    Box,
    Container,
    TextField,
    FormControl,
    FormLabel,
    Grid,
    Dialog,
    DialogContent,
    Slide,
    Typography,
    Fab,
} from '@mui/material';
import Virgin from '../assets/img/Virgin2.svg';
import {addDoc, collection,Timestamp} from 'firebase/firestore';
import {db} from '../utils/firestore';
import emailjs from '@emailjs/browser';
import {toast, ToastContainer} from 'react-toastify';
import {GrInstagram} from 'react-icons/gr';
import '../assets/sass/contact.scss';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up"  ref={ref} {...props} />;
  });

export default function Contact(props){
    const [openContact,setOpenContact] = useState(true)
    const [firstName,setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email,setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [company, setCompany] = useState("");
    const [message,setMessage] = useState("")

    const form = useRef();

    const sendEmail = () => {
            emailjs.sendForm("service_g7bex1e", "template_9g3ln15", form.current, "0OMSi7LxqbfghydME")
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };


    const ContactSucessful = () => {
        toast.success("Thanks for Contacting Us!", {
            position: toast.POSITION.TOP_CENTER
        })
    }
    
    

    const contactFromClose = () => {
        setOpenContact(false)
    }

    const contactFormOpen = () => {
        setOpenContact(true)
    }

    const submitForm = (e) => {
        e.preventDefault()
        sendEmail()
        addDoc(collection(db,'webcontacts'), {
            firstName: firstName,
            lastName: lastName,
            company: company,
            email: email,
            phone: phone,
            message:message,
            created: Timestamp.now(),
        }).then(()=> {
            setFirstName("");
            setLastName("");
            setCompany("");
            setEmail("");
            setPhone("");
            setMessage("");
        }).then(()=> {
            ContactSucessful();
            
        })
            
    }

    return(
        <React.Fragment>
        
            <Dialog
            
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            
            aria-describedby="contact-virgin-leaf"
            >
           <DialogContent sx={{pl:0, pr:0,}}>
           <ToastContainer/>
           <Container>
            <Box
            component="img"
            src={Virgin}
            sx={{
                width:'100%',
                height:'200px',
                backgroundColor:'black'
            }}/>
           <form ref={form} autoComplete="off" onSubmit={submitForm}>
            <Grid container justifyContent='center' spacing={1} direction="row">
                <Grid sx={{textAlign:'center', m:2}} item md={2}>
                    <Fab>
                        <a href="https://www.instagram.com/virginleaf/" rel="noreferrer" target="_blank">
                        <GrInstagram className="social-contact"/>
                        </a>
                    </Fab> 
                </Grid>
                <Grid container sx={{textAlign:'center', m:2}} justifyContent="start" alignItems="center" item md={8}>
                    <Typography variant="h4" sx={{textAlign:'center'}}>
                        Contact Us Today!
                    </Typography>
                </Grid>
                <Grid container item md={6} xs={12}>
                    <FormControl fullWidth={true} size="small" sx={{textAlign:'center', mt:1 }}>
                        <FormLabel>First Name</FormLabel>
                        <TextField  size="small" type="text" name="text" value={firstName} onChange={(e)=>setFirstName(e.target.value)}/>
                    </FormControl>
                </Grid>
                <Grid container item md={6} xs={12}>
                    <FormControl fullWidth={true} sx={{textAlign:'center',mt:1}}>
                        <FormLabel>Last Name</FormLabel>
                        <TextField size="small" type="text" name="lastName" value={lastName} onChange={(e)=>setLastName(e.target.value)}/>
                    </FormControl>
                </Grid>
                <Grid container item md={6} xs={12}>
                    <FormControl fullWidth={true}  sx={{textAlign:'center', mt:1,}}>
                        <FormLabel>Email</FormLabel>
                        <TextField size="small" type="email" name="email" value={email} onChange={(e)=>setEmail(e.target.value)}/>
                    </FormControl>
                </Grid>
                <Grid container item md={6} xs={12}>
                    <FormControl fullWidth={true}  sx={{textAlign:'center', mt:1,}}>
                        <FormLabel>Contact Number</FormLabel>
                        <TextField  size="small" type="text" name="phone" value={phone} onChange={(e)=>setPhone(e.target.value)}/>
                    </FormControl>
                </Grid>
                <Grid container item md={12} xs={12}>
                    <FormControl fullWidth={true}  sx={{textAlign:'center', mt:1,}}>
                        <FormLabel>Company</FormLabel>
                        <TextField  size="small" type="text" name="company" value={company} onChange={(e)=>setCompany(e.target.value)}/>
                    </FormControl>
                </Grid>
                <Grid container item md={12} xs={12}>
                    <FormControl fullWidth={true}  sx={{textAlign:'center', mt:1,}}>
                        <FormLabel>Message</FormLabel>
                        <TextField size="small" type="textarea" multiline rows={4}name="message" value={message} onChange={(e)=>setMessage(e.target.value)}/>
                    </FormControl>
                </Grid>
                <Grid container md={6} item justifyContent="center">
                <Button
                bgcolor="primary.main"
                sx={{
                    boxShadow: 2,
                    width: '150px',
                    height: '50px',
                    mt: 2,
                }}
                type="submit">Send Message</Button>
                </Grid>
                <Grid container md={6} item justifyContent="center">
                <Button
                variant='error'
                sx={{
                    boxShadow: 2,
                    width: '150px',
                    height: '50px',
                    mt: 2,
                }}
                onClick={props.close}>Close</Button>
                </Grid>
                
              
            </Grid>
            </form>
            </Container>
            
            
           
           </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}