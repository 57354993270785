import React, {useState,useEffect} from 'react';
import {
    Container,
    Grid, 
    Stack,
} from '@mui/material';
import {query, 
    collection, 
    onSnapshot,
    where, 
} from 'firebase/firestore';
import {db} from '../utils/firestore';
import { useNavigate } from "react-router-dom";
import V from '../assets/img/virginV.svg';
import Insta from '../assets/img/frame.png';
import Card from '../components/productcard';
import { useAuthState } from "react-firebase-hooks/auth";
import {auth} from '../utils/firestore';




export const PrintMenu = React.forwardRef((props,ref)=> {
   
   
   
    const [indica, setIndica] = useState([]);
    const [sativa, setSativa] = useState([]);
    const [hybrid, setHybrid] = useState([])
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate()
    


    useEffect(() => {
        const i = query(collection(db, 'products'), where('type', "==", 'INDICA'))
        onSnapshot(i, (querySnapShot) => {
            setIndica(querySnapShot.docs.map(doc =>({
                id: doc.id,
                data: doc.data(),
            })))
        })
        const s = query(collection(db, 'products'), where('type', "==", 'SATIVA'))
        onSnapshot(s, (querySnapShot) => {
            setSativa(querySnapShot.docs.map(doc =>({
                id: doc.id,
                data: doc.data(),
            })))
        })
        const h = query(collection(db, 'products'), where('type', "==", 'HYBRID'))
        onSnapshot(h, (querySnapShot) => {
            setHybrid(querySnapShot.docs.map(doc =>({
                id: doc.id,
                data: doc.data(),
            })))
        })
        
        if(!user) {
                navigate("/menu");
            }     
        
     
    },[])
    return(
        <React.Fragment>
        <div ref={ref} style={{width:'100%', backgroundColor:'black'}}>
            <Container>
                <Grid container alignItems="center" justifyContent="center" spacing={4}>
                    
                    <Grid item md={12}>
                        <Stack direction="horizonal" justifyContent="center"alignItems="center">
                            <h2 className="text-adjust text-center mt-3" style={{fontSize:'3em'}}>
                                Virgin Leaf Cannabis Menu
                            </h2>
                        </Stack>
                        <Grid item md={12}>
                        <h5 className="text-adjust text-center mt-3" style={{fontSize:'1em'}}>
                                We have prerolls and smalls, be sure to ask your sales rep today!
                            </h5>
                        </Grid>
                     </Grid>
                </Grid>
            </Container>
            <Container sx={{mt:3, mb:5,}}>
            <Grid container justifyContent="center" spacing={4}>
                <Grid item md={12}>
                <h2 className='text-adjust text-center' style={{fontSize:'2.5em'}}>
                    Hybrid
                </h2>
                </Grid>
                {hybrid.map((cards) => (
                    <Grid key={cards.id}  item md={4} xs={12}>
                    <h5 style={{fontSize: '2.5em'}} className="text-center text-adjust">${cards.data.price}</h5>
                    <Card 
                    id={cards.id}
                    product={cards.data.product}
                    type={cards.data.type}
                    img={(cards.data.imgUrl) ? cards.data.imgUrl: V}
                    des={cards.data.des}
                    thc={cards.data.thc}
                    terps={cards.data.terps}
                    value={cards.data.testUrl}
                    />
                </Grid>
                 ))}   
            </Grid>
            </Container>
            <Container sx={{mt:3}}>
                
            <Grid container justifyContent="center" spacing={4} direction="row">
            <Grid item md={12}>
                <h2 className='text-adjust text-center mb-3' style={{fontSize:'2.5em'}}>
                    Sativa
                </h2>
            </Grid>
            {sativa.map((cards) => (
                <Grid key={cards.id}  item md={4} xs={12}>
                <h5 style={{fontSize: '2.5em'}} className="text-center text-adjust">${cards.data.price}</h5>
                <Card 
                id={cards.id}
                product={cards.data.product}
                type={cards.data.type}
                img={(cards.data.imgUrl) ? cards.data.imgUrl: V}
                des={cards.data.des}
                thc={cards.data.thc}
                terps={cards.data.terps}
                value={cards.data.testUrl}
                />
               
            </Grid>
             ))}   
        </Grid>
        </Container>
        <Container sx={{mt:3}}>
            <Grid container justifyContent="center" spacing={4} direction="row">
                <Grid item md={12}>
                    <h2 className='text-center text-adjust mb-3' style={{fontSize:'2.5em'}}>
                        Indica
                    </h2>
                </Grid>
            {indica.map((cards) => (
                <Grid key={cards.id}  item md={4} xs={12}>
                <h5 style={{fontSize: '2.5em'}} className="text-center text-adjust">${cards.data.price}</h5>
                <Card sx={{transform:'scale(.01)'}}
                id={cards.id}
                product={cards.data.product}
                type={cards.data.type}
                img={(cards.data.imgUrl) ? cards.data.imgUrl: V}
                des={cards.data.des}
                thc={cards.data.thc}
                terps={cards.data.terps}
                value={cards.data.testUrl}
                />
            </Grid>
             ))}   
        </Grid>
        <Grid alignItems="center" justifyContent="center" container>
                <Grid md={12} item>
                    <h5 className="text-adjust mt-3 mb-3">
                        Follow us Instagram!
                    </h5>
                </Grid>
                <Grid md={3} item>
                    <img
                    src={Insta}
                    alt="Instagram Virgin Leaf Cannabis"
                    style={{width:'100%'}}/>
                </Grid>
                <Grid md={12} item>
                    <h5 className="text-adjust mt-3 mb-3">
                        See what we have been doing.
                    </h5>
                </Grid>
                
        
        </Grid>
        </Container>
       
        
        </div>
           
        </React.Fragment>
    );
})

export default PrintMenu;