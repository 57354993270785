import React, {useState,useEffect} from 'react';
import {
    Container,
    Grid, 
    Typography,
    
} from '@mui/material';
import {query, 
    collection, 
    onSnapshot,
    where, 
} from 'firebase/firestore';
import {db} from '../utils/firestore';
import V from '../assets/img/virginV.svg';
import Card from './productcard';



export default function Menu(props) {
    const [indica, setIndica] = useState([]);
    const [sativa, setSativa] = useState([]);
    const [hybrid, setHybrid] = useState([])
    


    useEffect(() => {
        const i = query(collection(db, 'products'), where('type', "==", 'INDICA'))
        onSnapshot(i, (querySnapShot) => {
            setIndica(querySnapShot.docs.map(doc =>({
                id: doc.id,
                data: doc.data(),
            })))
        })
        const s = query(collection(db, 'products'), where('type', "==", 'SATIVA'))
        onSnapshot(s, (querySnapShot) => {
            setSativa(querySnapShot.docs.map(doc =>({
                id: doc.id,
                data: doc.data(),
            })))
        })
        const h = query(collection(db, 'products'), where('type', "==", 'HYBRID'))
        onSnapshot(h, (querySnapShot) => {
            setHybrid(querySnapShot.docs.map(doc =>({
                id: doc.id,
                data: doc.data(),
            })))
        })
        
     
    },[])

    
    
    
    

    

    

    return(
        <React.Fragment>

            <Container sx={{mt:3, mb:5,}}>
                <Grid container spacing={4} justifyContent="center" direction="row">
                    <Grid item md={12}>
                    <h2 className='text-adjust text-center' style={{fontSize:'2.5em'}}>
                        Indica
                    </h2>
                    </Grid>
                    {indica.map((cards) => (
                        <Grid key={cards.id}  item md={4} xs={12}>
                        <Card 
                        id={cards.id}
                        product={cards.data.product}
                        type={cards.data.type}
                        img={(cards.data.imgUrl) ? cards.data.imgUrl: V}
                        des={cards.data.des}
                        thc={cards.data.thc}
                        terps={cards.data.terps}
                        value={cards.data.testUrl}
                        />
                    </Grid>
                     ))}   
                </Grid>
                </Container>
                <Container sx={{mt:3}}>
                <Grid item md={12}>
                    <h2 className='text-adjust text-center' style={{fontSize:'2.5em'}}>
                        Sativa
                    </h2>
                    </Grid>
                <Grid container spacing={4} justifyContent="center" direction="row">
                {sativa.map((cards) => (
                    <Grid key={cards.id}  item md={4} xs={12}>
                    <Card 
                    id={cards.id}
                    product={cards.data.product}
                    type={cards.data.type}
                    img={(cards.data.imgUrl) ? cards.data.imgUrl: V}
                    des={cards.data.des}
                    thc={cards.data.thc}
                    terps={cards.data.terps}
                    value={cards.data.testUrl}
                    />
                </Grid>
                 ))}   
            </Grid>
            </Container>
            <Container sx={{mt:3}}>
                <Grid item md={12}>
                    <h2 className='text-adjust text-center' style={{fontSize:'2.5em'}}>
                        Hybrid
                    </h2>
                    </Grid>
                <Grid container spacing={4} justifyContent="center" direction="row">
                {hybrid.map((cards) => (
                    <Grid key={cards.id}  item md={4} xs={12}>
                    <Card 
                    id={cards.id}
                    product={cards.data.product}
                    type={cards.data.type}
                    img={(cards.data.imgUrl) ? cards.data.imgUrl: V}
                    des={cards.data.des}
                    thc={cards.data.thc}
                    terps={cards.data.terps}
                    value={cards.data.testUrl}
                    />
                </Grid>
                 ))}   
            </Grid>
            </Container>
        </React.Fragment>
    )
}