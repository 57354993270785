import React from 'react'
import D from '../assets/img/decrypted-tech.svg';
import V from '../assets/img/virginV.svg';
import {Col, Row, Container, Button} from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';

import '../assets/sass/main.scss';




export default function Footer(){
    const navigate = useNavigate()

    const admin = () => {
        navigate("/login")
    }
    return(
       <React.Fragment>
       <section style={{backgroundColor:'black'}} className="p3 pt-0">
        <Container>
            <Row className="d-flex align-items-center">
                <Col md={7} lg={8} className="text-center">
                    <div className="p-3 text-adjust">
                        © Decrypted-Tech LLC
                    </div>
                </Col>
                <Col md={5} lg={4} className="text-center text-adjust text-md-end">
                    <Button variant='light' className="text-adjust" onClick={admin}>Admin</Button>
                </Col>
            </Row>
            </Container>
       </section>
        
           
       </React.Fragment>
       

    )
}