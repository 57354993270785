import React from 'react'; 
import { Nav, Navbar, Container,} from 'react-bootstrap';
import { FaInstagram  } from 'react-icons/fa';

import Logo from '../assets/img/Virgin2.svg';
import {useNavigate} from 'react-router-dom';



import '../assets/sass/sitenav.scss';



export default function SiteNav (props) {
    const navigate = useNavigate()
   

    const mainMenu = () => {
        navigate("/menu")
    }

    const pricing = () => {
        navigate("/pricesheet")
    }
    
    return(
        <React.Fragment>

            <Navbar sticky="top" className="navbar-main" collapseOnSelect expand='lg'>
                
                <Container>
                    <Navbar.Brand href="#home">
                       
                            <img
                            className="nav-logo"
                            src={Logo}
                            alt="Virgin Cannabis"
                            />
                        </Navbar.Brand>
                        <Navbar.Toggle style={{borderColor:'white'}} aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse style={{backgroudColor:'white'}}  id="basic-navbar-nav">
                        <Nav className="mt-5">
                            <Nav.Link onClick={props.add}>Add Product</Nav.Link>
                            <Nav.Link onClick={pricing}>Price Sheet</Nav.Link>
                            <Nav.Link onClick={props.message}>Messages</Nav.Link>
                            <Nav.Link onClick={mainMenu}>Menu</Nav.Link>
                        </Nav>
                        </Navbar.Collapse>
                            <Nav className="mt-5">
                                <Nav.Link as='a' href="https://www.instagram.com/virginleaf/" target="_blank">
                                    
                                        <FaInstagram className="social-icons"/>
                                    
                                </Nav.Link>
                                <Nav.Link className="mt-2" style={{backgroundColor:'gold', color:'black'}} onClick={props.logout}>Logout</Nav.Link>
                            </Nav>
                </Container>
            </Navbar>
            
        </React.Fragment>
    );
} 