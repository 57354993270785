import React, {useState, useEffect} from 'react';
import {
    Button,
    Box,
    Container,
    TextField,
    FormControl,
    FormLabel,
    Grid,
    Dialog,
    DialogContent,
    Slide,
    Typography,
} from '@mui/material';
import Virgin from '../assets/img/Virgin2.svg';
import { useNavigate } from 'react-router-dom'

import {auth, LoginNormal} from '../utils/firestore';
import {toast, ToastContainer} from 'react-toastify';
import { useAuthState } from 'react-firebase-hooks/auth';
import '../assets/sass/contact.scss';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left"  ref={ref} {...props} />;
  });

export default function Contact(props){
    const [openContact,setOpenContact] = useState(true)
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [user,loading,error] = useAuthState(auth);
    const navigate = useNavigate();

    const login = async(email,password)=> {
        await LoginNormal(email,password)
        if (user) {
            loginSuccess()
            setEmail("")
            setPassword("")
        }
        if(!user) {
            loginNotSuccessful();
            setEmail("")
            setPassword("")
        }
    }

    useEffect(() => {
        if (loading) {
         
          return;
        }
        if (user) {
            navigate("/dashboard");
        }
      }, [user, loading]);

    
    
    const loginSuccess = () => {
        toast.success("Welcome back, Virgin Cannabis", {
            position: toast.POSITION.TOP_CENTER
        })
    }
    
    const loginNotSuccessful = () => {
        toast.error("Try Again or Reset Password", {
            position: toast.POSITION.TOP_CENTER
        })
    } 

    const contactFromClose = () => {
        setOpenContact(false)
    }

    const contactFormOpen = () => {
        setOpenContact(true)
    }

    const navMenu = () => {
        navigate("/menu")
    }

    return(
        <React.Fragment>
        <ToastContainer/>
       
            <Dialog
            
            open={true}
            TransitionComponent={Transition}
            keepMounted
          
            aria-describedby="contact-virgin-leaf"
            >
           <DialogContent sx={{pl:0, pr:0,}}>
           <Container>
            <Box
            component="img"
            src={Virgin}
            sx={{
                width:'100%',
                height:'200px',
                backgroundColor:'black'
            }}/>
           <form>
            <Grid container justifyContent='center' spacing={1} direction="row">
               
                <Grid container sx={{textAlign:'center', m:2}} justifyContent="center" alignItems="center" item md={8}>
                    <Typography variant="h4" sx={{textAlign:'center'}}>
                        Admin Login
                    </Typography>
                </Grid>
                <Grid container item md={6} xs={12}>
                    <FormControl fullWidth={true} size="small" sx={{textAlign:'center', mt:1 }}>
                        <FormLabel>Email</FormLabel>
                        <TextField  size="small" type="email" autoComplete='username' id="text" value={email} onChange={(e)=>setEmail(e.target.value)}/>
                    </FormControl>
                </Grid>
                <Grid container item md={6} xs={12}>
                    <FormControl fullWidth={true} sx={{textAlign:'center',mt:1}}>
                        <FormLabel>Password</FormLabel>
                        <TextField size="small" type="password" autoComplete='current-password' id="password" value={password} onChange={(e)=>setPassword(e.target.value)}/>
                    </FormControl>
                </Grid>
                
                
                <Grid container md={6} item justifyContent="center">
                <Button
                bgcolor="primary.main"
                sx={{
                    boxShadow: 2,
                    width: '150px',
                    height: '50px',
                    mt: 2,
                }}
                onClick={(e)=>login(email, password)}>Login</Button>
                </Grid>
                <Grid container md={6} item justifyContent="center">
                <Button
                variant='error'
                sx={{
                    boxShadow: 2,
                    width: '150px',
                    height: '50px',
                    mt: 2,
                }}
                onClick={navMenu}>Main Menu</Button>
                </Grid>
                
              
            </Grid>
            </form>
            </Container>
            
            
           
           </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}