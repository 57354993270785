// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore} from  "firebase/firestore";
import {getStorage} from "firebase/storage"
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  browserSessionPersistence,
  setPersistence,

} from "firebase/auth"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API,
  authDomain: "virgin-leaf-menu.firebaseapp.com",
  projectId: "virgin-leaf-menu",
  storageBucket: "virgin-leaf-menu.appspot.com",
  messagingSenderId: "1008428992052",
  appId: "1:1008428992052:web:7efc3be2e5b5486c85ac61",
  measurementId: "G-5FRQFBJDKE"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app)
const auth = getAuth(app);
const storage = getStorage()

const LoginNormal = async(email,password) => {
  try {
      setPersistence(auth, browserSessionPersistence)
      .then(() => {
        return [
          signInWithEmailAndPassword(auth, email, password),
        
        ]
    }); 
  }catch (err) {
    console.error(err);
    alert(err.message);
  }
}

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout =() => {
  signOut(auth);
};


export {db,auth, storage, LoginNormal,sendPasswordReset,logout}


