import React, {useState, useEffect, useRef} from 'react';
import {auth, db, storage} from '../utils/firestore';
import {signOut} from 'firebase/auth';
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import ProCard from '../components/dashcard';
import {Container, Row, Col, Form, Button, Offcanvas} from 'react-bootstrap';
import {ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import {addDoc, collection, snapshotEqual, Timestamp, updateDoc, onSnapshot, query, doc, deleteDoc } from 'firebase/firestore';
import CleanCard from '../components/productcard';
import {ToastContainer, toast} from 'react-toastify';
import "react-toastify/ReactToastify.min.css";
import  Vlogo from '../assets/img/virginV.svg';
import Nav from '../components/dashNav';
import ContactMessage from './contactMessages'
import '../assets/sass/main.scss';
import Print from '../pages/pricesheet';









export default function DashBoard(props) {


        const [user, loading, error] = useAuthState(auth);
        const [imgUrl,setImgUrl] = useState('');
        const [testUrl, setTestUrl] =useState('')
        const [des,setDes] =useState("")
        const [file, setFile] = useState(null);
        const [percentage, setPercentage] = useState(0);
        const [testResults, setTestResults] = useState('')
        const [showAdd, setShowAdd] = useState(false)
        const [showEdit, setShowEdit] = useState(false)
        const [product, setProduct] = useState('')
        const [price, setPrice] = useState('')
        const [type, setType] =useState('')
        const [thc,setThc] =useState('')
        const [terps, setTerps]=useState('')
        const [dashItems,setDashItems] = useState([]);
        const [updateitem,setUpdateItem]= useState('');
        const [update,setUpdate] = useState('')
        const [messages,setMessages] = useState([]);
        const [openMessage, setOpenMessage] =useState(false)
        const [flag, setflag] = useState(false)
        const navigate = useNavigate()
       

        const showMessage = () => {
            setOpenMessage(true);
        }

        const closeMessage =()=> {
            setOpenMessage(false)
        }

        



        const uploadComplete = () => {
            toast.success("Upload is complete!", {
                position: toast.POSITION.TOP_CENTER
            })
        }
        const updateSubmitted = () => {
            toast.success("Upload is complete!", {
                position: toast.POSITION.TOP_CENTER
            })
        }

        const formSubmitted = () => {
            toast.success("New product added!", {
                position: toast.POSITION.TOP_CENTER
            })
        }
    
        const noImagefound = () => {
            toast.error("Please Select a Image!", {
                position: toast.POSITION.TOP_CENTER
            })
        }
    
        const noTestfound = () => {
            toast.error("Please upload a Test Result!", {
                position: toast.POSITION.TOP_CENTER
            })
        }
    
        const noChill= () => {
            toast.error("Please wait, its working!", {
                position: toast.POSITION.TOP_CENTER
            })
        }
    
        
    const openAdd = () => {
        setShowAdd(true);
    }

    const closeAdd = () => {
        setShowAdd(false);
    }

    const resetForm = () => {
            setFile(null)
            setProduct('')
            setTestResults(null)
            setPrice('')
            setThc('')
            setTerps('')
            setImgUrl('')
            setTestUrl('')
            setUpdateItem("")
            setDes("")
    }
    const handleAddProduct = (e) => {
        e.preventDefault();
        addDoc(collection(db,'products'), {
            product: product,
            des: des,
            type:type,
            price: price,
            thc: thc,
            terps: terps,
            testUrl: testUrl,
            imgUrl: imgUrl,
            created: Timestamp.now()
        })
        formSubmitted();
        resetForm();
    }

    const updateProduct = async() => {
            const id = updateitem
            const itemRef = doc(db, 'products',id)
           updateDoc(itemRef, {
                product: product,
                des: des,
                price: price,
                type:type,
                thc: thc,
                terps: terps,
                testUrl: testUrl,
                imgUrl: imgUrl,
                created: Timestamp.now()

           })
           updateSubmitted()
   
    }
    const messageHistory = async(id) => {
       setflag(true)
    const itemRef = doc(db, 'webcontacts',id)
       updateDoc(itemRef, {
            flag: flag,
       })
}


    const handleImg = async() => {
       
        if(!file) {

            noImagefound()
        } 
            const storageRef = ref(storage,`/virgin/img/${file.name}`)
            const uploadTask = uploadBytesResumable(storageRef, file, 'data_url');
            uploadTask.on(
                "state_changed",
                (snaphot) => {
                    const percent = Math.round(
                        (snaphot.bytesTransferred / snapshotEqual.totalBytes) * 100
                    );
                    setPercentage(percent)
                },
                (err) => (err),
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((imgUrl) => {
                        setImgUrl(imgUrl);
                        if (!imgUrl) {
                            noChill();
                            uploadComplete();
                        } else {
                            uploadComplete()
                        }
                    })
                }, 
            )
        
    }

    useEffect(() => {
        
        const q = query(collection(db, 'products',))
        onSnapshot(q, (querySnapShot) => {
            setDashItems(querySnapShot.docs.map(doc =>({
                id: doc.id,
                data: doc.data(),
            })))
        })
        const m = query(collection(db,'webcontacts',))
        onSnapshot(m,(querySnapShot)=>{
            setMessages(querySnapShot.docs.map(doc=>({
                id: doc.id,
                data: doc.data(),
            })))
        })
        if (!user) {
            navigate("/menu")
        }
       
       
    },[])
       
        const handleTest = async() => {
       
            if(!testResults) {
                noTestfound()
            } 
                const storageRef = ref(storage,`/virgin/tests/${testResults.name}`)
                const uploadTask = uploadBytesResumable(storageRef, testResults, 'data_url');
                uploadTask.on(
                    "state_changed",
                    (snaphot) => {
                        const percent = Math.round(
                            (snaphot.bytesTransferred / snapshotEqual.totalBytes) * 100
                        );
                        setPercentage(percent)
                    },
                    (err) => (err),
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                            setTestUrl(url);
                            if (!testUrl) {
                                noChill();
                                uploadComplete();
                            } else {
                                uploadComplete();
                            }
                        })
                    }, 
                )
            
        }

    const showOpenEdit = async(id) => {
        setUpdateItem(id)
        setShowEdit(true)
    }   
        
    const deleteCard = async(id) =>{
        await deleteDoc(doc(db,'products',id))
    }

    const deleteMessage = async(id) => {
        await deleteDoc(doc(db,'webcontacts',id))
    }

    const closeEdit = () => {
        setShowEdit(false)
        resetForm();
    }

    const logoutNow =()=> {
        signOut(auth).then(()=>{
            navigate('/menu');
        })
        
    }

    return(
        <React.Fragment>
       
        <div className="menu-div">
            <Nav add={openAdd} logout={(e)=>logoutNow()} message={showMessage}/>
            <Container>
            <ToastContainer/>
            <Offcanvas 
                style={{
                    padding:'10px',
                    width:'40%', 
                    backgroundColor: 'black',
                    color:'white',
                    BoxShadow:'2px 3px 15px'
                }}
                show={openMessage} 
                scroll={true}
                onHide={closeMessage}
              
            >
            <Offcanvas.Header>
            <Offcanvas.Title>
            <Row className="mt-3 align-items-center">
                    <Col>
                    <h3 className="text-adjust" style={{fontSize:'3em'}}>Message Panel</h3>
                    <p className="text-adjust">View Messages from your Site here.</p>
                    </Col>
                </Row>
                </Offcanvas.Title>
                
                </Offcanvas.Header>
            <Offcanvas.Body>
            <Row className="m-3">
                {messages.map((item) => (
                    <Col className="mb-5" key={item.id} md={12}>
                       <ContactMessage
                       id={item.id}
                       date={new Date(item.data.created.seconds * 1000).toLocaleDateString("en-us")}
                       firstName={item.data.firstName}
                       lastName={item.data.lastName}
                       email={item.data.email}
                       phone={item.data.phone}
                       company={item.data.company}
                       message={item.data.message}
                       flag={(e)=>messageHistory(item.id)}
                       delete={(e)=>deleteMessage(item.id)}
                       />
                      
                    </Col>
                ))}
                </Row>
            </Offcanvas.Body>
            </Offcanvas>
            <Offcanvas 
                style={{
                    padding:'10px',
                    width:'50%', 
                    backgroundColor: 'white',
                    color:'black'
                }}
                show={showAdd} 
                scroll={true}
                onHide={closeAdd}
              
            >
            <Offcanvas.Header closeButton="true">
            <Offcanvas.Title>
                </Offcanvas.Title>
                <Row className="justify-content-center mt-3">
                    <Col>
                    <h3 style={{textAlign:'center'}}>Add New Product</h3>
                    <p style={{textAlign:'center'}}>Preview Card</p>
                    </Col>
                </Row>
                </Offcanvas.Header>
            <Offcanvas.Body>
            <Row className="justify-content-center">
            <Col className="align-items-center" md={6}>
                <CleanCard
                img={(imgUrl) ? imgUrl: Vlogo}
                value={testUrl}
                des={des}
                product={product}
                terps={terps}
                thc={thc}
                type={type}
                />
            </Col>
            <Col md={6}>
                <Row>
                    <Col md={10} className="mb-3">
                        <Form.Label>Step 1: Upload Photo</Form.Label>
                        <Form.Control type="file" id={file} onInput={(e)=>setFile(e.target.files[0])}/>
                        <Button className="form-btn" onClick={handleImg}>Upload Photo</Button>
                    </Col>
                </Row>
                <Row>
                    <Col md={10} className="mb-3">
                        <Form.Label>Step 2: Upload Test Results</Form.Label>
                        <Form.Control type="file" id={testResults} onInput={(e)=>setTestResults(e.target.files[0])}/>
                        <Button className="form-btn" onClick={handleTest}>Upload Test</Button>
                    </Col>
                </Row>
                <Row>
                    <h6>Step 3: Add Details and Submit</h6>
                </Row>
                <Row>
                    <Col md={10} className="mb-3">
                        <Form.Label>Product Name</Form.Label>
                        <Form.Control type="text" value={product} name="product" onChange={(e)=>setProduct(e.target.value.toUpperCase())}/>
                    </Col>
                </Row>
                <Row>
                <Col md={10} className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" value={des} rows={2} name="des" onChange={(e)=>setDes(e.target.value)}/>
                </Col>
                </Row>
                <Row>
                    <Col md={10} className="mb-3">
                        <Form.Label>Strain Type</Form.Label>
                        <Form.Select aria-label="Strain Type" name="type" id={type} onChange={(e)=>setType(e.target.value.toUpperCase())}>
                                <option>Select Type</option>
                                <option value='Sativa'>Sativa</option>
                                <option value='Indica'>Indica</option>
                                <option value='Hybrid'>Hybrid</option>
                        </Form.Select>
                    </Col>
                </Row>
                <Row>
                <Col md={5} className="mb-3">
                    <Form.Label>THC Percentage</Form.Label>
                    <Form.Control type="text" value={thc} name="thc" onChange={(e)=>setThc(e.target.value)}/>
                </Col>
                <Col md={5} className="mb-3">
                    <Form.Label>Terp Percentage</Form.Label>
                    <Form.Control type="text" value={terps} name="terps" onChange={(e)=>setTerps(e.target.value)}/>
                </Col>
                </Row>
                <Row>
                <Col md={10} className="mb-3">
                    <Form.Label>Price</Form.Label>
                    <Form.Control type="text" value={price} name="price" onChange={(e)=>setPrice(e.target.value)}/>
                </Col>
                </Row>
                <Row className="align-content-center">
                    <Col md={5}>
                        <Button variant='success' onClick={handleAddProduct}>Submit</Button>
                    </Col>
                    <Col md={5}>
                        <Button variant="danger" onClick={resetForm}>Clear Form</Button>
                    </Col>
                       
                </Row>
            </Col>
            
            </Row>
            </Offcanvas.Body>
            </Offcanvas>
            <Offcanvas 
            style={{
                padding:'10px',
                width:'50%', 
                backgroundColor: 'white',
                color:'black'
            }}
            show={showEdit} 
            scroll={true}
            onHide={closeEdit}
            closeButton
            >
            
            <Offcanvas.Header closeButton>
            <Offcanvas.Title>
                </Offcanvas.Title>
                <Row className="justify-content-center mt-3">
                    <Col>
                    <h3 style={{textAlign:'center'}}> Update Product</h3>
                    <p style={{textAlign:'center'}}>Preview Card</p>
                    </Col>
                </Row>
                </Offcanvas.Header>
            <Offcanvas.Body>
            <Row className="justify-content-center">
                <Col md={6}>
                <CleanCard
                img={(imgUrl) ? imgUrl: Vlogo}
                value={testUrl}
                product={product}
                des={des}
                terps={terps}
                thc={thc}
                type={type}
                />
            </Col>
               
            <Col md={6}>
                <Row>
                    <Col md={10} className="mb-3">
                        <Form.Label>Step 1: Upload Photo</Form.Label>
                        <Form.Control type="file" id={file} name="file" onInput={(e)=>setFile(e.target.files[0])}/>
                        <Button className="form-btn" onClick={handleImg}>Upload Photo</Button>
                    </Col>
                </Row>
                <Row>
                    <Col md={10} className="mb-3">
                        <Form.Label>Step 2: Upload Test Results</Form.Label>
                        <Form.Control type="file" id={testResults} onInput={(e)=>setTestResults(e.target.files[0])}/>
                        <Button className="form-btn" onClick={handleTest}>Upload Test</Button>
                    </Col>
                </Row>
                <Row>
                    <h6>Step 3: Add Details and Submit</h6>
                </Row>
                <Row>
                    <Col md={10} className="mb-3">
                        <Form.Label>Product Name</Form.Label>
                        <Form.Control type="text" value={product} name="product" onChange={(e)=>setProduct(e.target.value.toUpperCase())}/>
                    </Col>
                </Row>
                <Row>
                <Col md={10} className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" value={des} rows={2} name="des" onChange={(e)=>setDes(e.target.value)}/>
                </Col>
                </Row>
                <Row>
                    <Col md={10} className="mb-3">
                        <Form.Label>Strain Type</Form.Label>
                        <Form.Select aria-label="Strain Type" name="type" id={type} onChange={(e)=>setType(e.target.value.toUpperCase())}>
                                <option>Select Type</option>
                                <option value='Sativa'>Sativa</option>
                                <option value='Indica'>Indica</option>
                                <option value='Hybrid'>Hybrid</option>
                        </Form.Select>
                    </Col>
                </Row>
                <Row>
                <Col md={5} className="mb-3">
                    <Form.Label>THC Percentage</Form.Label>
                    <Form.Control type="text" value={thc} name="thc" onChange={(e)=>setThc(e.target.value)}/>
                </Col>
                <Col md={5} className="mb-3">
                    <Form.Label>Terp Percentage</Form.Label>
                    <Form.Control type="text" value={terps} name="terps" onChange={(e)=>setTerps(e.target.value)}/>
                </Col>
                </Row>
                <Row>
                <Col md={10} className="mb-3">
                    <Form.Label>Price</Form.Label>
                    <Form.Control type="text" value={price} name="price" onChange={(e)=>setPrice(e.target.value)}/>
                </Col>
                </Row>
                <Row className="align-content-center">
                    <Col md={5}>
                        <Button variant='success' onClick={updateProduct}>Update</Button>
                    </Col>
                    <Col md={5}>
                        <Button variant="danger" onClick={resetForm}>Clear Form</Button>
                    </Col>
                       
                </Row>
            </Col>
            
            </Row>
            </Offcanvas.Body>
            </Offcanvas>
                <Row className="justify-content-center mt-3">
                </Row>
                <Row>
                {dashItems.map((card) => (
                    <Col className="mt-5 mb-5" key={card.id} md={4}>
                        <ProCard
                        id={card.id}
                        img={(card.data.imgUrl) ? card.data.imgUrl: Vlogo}
                        value={card.data.testUrl}
                        des={card.data.des}
                        product={card.data.product}
                        terps={card.data.terps}
                        thc={card.data.thc}
                        type={card.data.type}
                        clickedit={(e)=>showOpenEdit(card.id).then(()=>{
                            setProduct(card.data.product)
                            setImgUrl(card.data.imgUrl)
                            setTerps(card.data.terps)
                            setTestUrl(card.data.testUrl)
                            setThc(card.data.thc)
                            setType(card.data.type)
                            setPrice(card.data.price)
                            setDes(card.data.des)
                        })}
                        clickdel={(e)=>deleteCard(card.id)}
                        >
                        </ProCard>
                      
                    </Col>
                ))}
                </Row>
                <Row className="m-3">
               
                </Row>
            </Container>
            </div>
        </React.Fragment>
    )
}