import React, {useState} from 'react'; 
import { Nav, Navbar, Container} from 'react-bootstrap';
import { FaInstagram  } from 'react-icons/fa';
import Logo from '../assets/img/Virgin2.svg';
import ContactForm from './contact';
import '../assets/sass/sitenav.scss';
;


export default function SiteNav (props) {

    const [contact, setContact] = useState(false)
    const contactClose = () => {
        setContact(false)
    }
    const contactOpen = () => {
        setContact(true)
    }
    return(
        <React.Fragment>

            <Navbar sticky="top" className="navbar-main" collapseOnSelect expand='lg'>
                <ContactForm
                    open={contact}
                    close={contactClose}
                />
                <Container>
                    <Navbar.Brand href="#home">
                       
                            <img
                            className="nav-logo"
                            src={Logo}
                            alt="Virgin Cannabis"
                            />
                        </Navbar.Brand>
                       
                            <Nav className="justify-content-end mt-2">
                               <Nav.Link className='linkage gradient-text' onClick={contactOpen}
                               style={{
                                    marginTop: '20px',
                                  
                                }}>Contact</Nav.Link>
                               
                                <Nav.Link as='a' href="https://www.instagram.com/virginleaf/" target="__blank">
                                    
                                        <FaInstagram className="social-icons"/>
                                    
                                </Nav.Link>
                                
                            </Nav>
                </Container>
            </Navbar>
            
        </React.Fragment>
    );
} 