import React from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Fab, Grid } from "@mui/material";
import { FaFlag, FaMinus,} from "react-icons/fa";



export default function ContactMessages(props){
       
    return(
        <React.Fragment>
        
            <Card sx={{width:'24rem',}}>
                <CardContent>
                    <Grid container spacing={1}></Grid>
                    <Grid item md={12}>
                       <Typography variant='h6'>
                       Date Recieved: {props.date}
                       </Typography>
                    </Grid>
                    <Grid item md={12} sx={{mt:3,}}>
                        <Typography>
                        From: {props.firstName} {props.lastName} @ {props.company}
                        </Typography>
                          
                    </Grid>
                    <Grid item md={12} sx={{mt:3,}}>
                    <Typography>
                        Email: {props.email}
                    </Typography>
                    </Grid>
                    <Grid item md={12} sx={{mt:3,}}>
                    <Typography>
                        Phone: {props.phone}
                    </Typography>
                    </Grid>
                    <Grid item md={12} sx={{mt:3,}}>
                    <Typography>
                         Message: {props.message}
                    </Typography>
                    </Grid>
                </CardContent>
                <CardActions disableSpacing={true}>
                    <Grid container justifyContent="flex-end" sx={{mt:1}} spacing={4}>
                        <Grid item>
                        <Fab  sx={{backgroundColor:'green'}} onClick={props.flag}>
                            <FaFlag/>
                        </Fab>
                        </Grid>
                    <Grid item>
                    <Fab sx={{
                        color:'white',
                         backgroundColor:'red',
                         '&:hover':{
                            color:'red',borderColor:'red'}}
                        }
                        onClick={props.delete}   
                        >
                        <FaMinus/>
                    </Fab>
                    </Grid>
                    </Grid>
                </CardActions>
            </Card>
          
        </React.Fragment>
    )
}
