import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import Age from '../src/pages/age';
import Menu from './pages/menu';
import LoginPage from './pages/login'
import DashBoard from './pages/dashBoardv2';
import Price from './pages/pricesheet';
import "react-datepicker/dist/react-datepicker.css";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {createTheme, ThemeProvider} from '@mui/material';

const virginTheme = createTheme({
  palette: {
    primary: {
      main: '#9a8438',
      light: '#fdee7f',
      dark: '#8b7337'
    },
    secondary: {
      main: '#000407',
      light: '#2c2e30',
      dark: '#000',
    }
  }
})


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={virginTheme}>
  <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<Age/>}/>
      <Route path="dashBoard" element={<DashBoard/>} />
      <Route path="menu" element={<Menu />} />
      <Route path="login" element={<LoginPage/>}/>
      <Route path="pricesheet" element={<Price/>}/>
    </Routes>
  </BrowserRouter>
  </ThemeProvider>
);


